import React from "react"
import { ButtonVariant } from "../components/RedesignComponents/TailWindTestComponents/ButtonVariant/ButtonVariant";
import TestComponent from "../components/RedesignComponents/TailWindTestComponents/TestComponent/TestComponent";
import SelectComponent from "../components/RedesignComponents/Select/Select";

const buttonVariants = [
  "default",
  "destructive",
  "outline",
  "secondary",
  "ghost",
  "link",
] as const;

const selectOptions = {
  backgroundColor: "orange",
  className: "",
  defaultValue: "",
  options: [
    {
      id: "1",
      label: "Option 1",
    },
    {
      id: "2",
      label: "Option 2",
    },
    {
      id: "3",
      label: "Option 3",
    },
    {
      id: "4",
      label: "Option 4",
    },
  ],
}

export default function TailwindExamplePage() {
  return (
    <>
      <div className="flex justify-center content-center p-20">
        <TestComponent />
      </div>
      <div className="flex justify-center content-center p-20">
        <SelectComponent options={selectOptions.options} backgroundColor={selectOptions.backgroundColor} onChange={function (id: string | number): void {
          throw new Error("Function not implemented.");
        } }  />
      </div>
      <div className="flex justify-center content-center">
        <ul className="items-center space-y-1 inline-flex space-x-1 list-none">
          {buttonVariants.map((variant) => (
            <li className="!m-1" key={variant}>
              <ButtonVariant variant={variant} className="capitalize">
                {variant}
              </ButtonVariant>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
